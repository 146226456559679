import React, { useState } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import Logo from "../../assets/logos/logo.png";
import BBB from "../../assets/logos/bbb.png";
import DA from "../../assets/icons/drop.png";

export default function Header() {
  const [show, setShow] = useState(false);
  const [drop, setDrop] = useState(false);

  const toggleDropdown = () => {
    setShow(!show);
  };

  const handleMouseEnter = () => {
    setDrop(true);
  };

  const handleMouseLeave = () => {
    setDrop(false);
  };
  return (
    <div className="headerBase">
      <div className="headerContent">
        <div className="headerFlex">
          <Link
            to="/home"
            onClick={() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
            style={{ textDecoration: "none", height: "100%" }}
          >
            <img
              src={Logo}
              alt="Top Roofing Company in Spring"
              className="logoImg"
            />
          </Link>
        </div>
        <div className="headerFlex">
          <div className="navTxtDiv">
            <Link
              to="/home"
              onClick={() => setShow(false)}
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt">HOME</p>
            </Link>
          </div>
          <div className="navTxtDiv">
            <Link to="/about-us" style={{ textDecoration: "none" }}>
              <p className="navTxt">ABOUT</p>
            </Link>
          </div>
          <div className="navTxtDiv">
            <Link to="/roofing" style={{ textDecoration: "none" }}>
              <p className="navTxt">ROOFING</p>
            </Link>
          </div>

          <div className="navTxtDivDrop">
            <div
              className="dropDownParentDiv"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {/* <Link to="/service" style={{ textDecoration: "none" }}> */}
              <p className="navTxt">SERVICES</p>
              {/* </Link> */}
              <div className="arrowIMg">
                <img
                  src={DA}
                  alt="arrow"
                  className="arrowImg"
                  style={{
                    transform: drop ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 1.0s",
                  }}
                />
              </div>
            </div>
            {drop && (
              <div
                className="dropDownDiv"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div>
                  <Link
                    to="storm-service"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">STORM SERVICE</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="roofing-service"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">ROOFING</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="roofing-company"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">ROOFING COMPANY</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="roof-repair"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">ROOF REPAIR</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="roof-inspection"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">ROOF INSPECTION</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="roof-restoration"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">ROOF RESTORATION</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="remodeling-company"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">REMODELING COMPANY</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="kitchen-remodeling"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">KITCHEN REMODELING</p>
                  </Link>
                </div>
                <div className="hrDrop"></div>
                <div>
                  <Link
                    to="bedroom-remodeling"
                    style={{ textDecoration: "none" }}
                    onClick={() => setDrop(false)}
                  >
                    <p className="navTxt">BATHROOM REMODELING</p>
                  </Link>
                </div>

                <div className="hrDrop"></div>
              </div>
            )}
          </div>
          {/* <div className="navTxtDiv">
            <Link
              onClick={() => setShow(false)}
              to="/blog"
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt">BLOG</p>
            </Link>
          </div> */}
          <div className="navTxtDiv">
            <Link
              onClick={() => setShow(false)}
              to="/contact-us"
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt">CONTACT</p>
            </Link>
          </div>
        </div>
        <div className="headerFlex">
          <div className="rightEndHeader">
            <img src={BBB} alt="logo" className="logoBbb" />
            <a href="tel:832-388-5393">
              <button className="callNowBtn">CALL NOW</button>
            </a>
          </div>
        </div>
      </div>

      <div className="respHeaderContent">
        <Link
          onClick={() => setShow(false)}
          to="/home"
          style={{
            textDecoration: "none",
            height: "100%",
          }}
        >
          <div className="repHeaderFlex">
            <img
              src={Logo}
              alt="Top Roofing Company in Spring"
              className="logoImgResp"
            />
          </div>
        </Link>

        <div className="repHeaderFlex">
          {/* <a  onClick={() => toggleDropdown()} className="btnDiv"> */}
          <a href="tel:832-388-5393">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="50"
              height="50"
              viewBox="0 0 50 50"
            >
              <path
                fill="#04adeb"
                // fill="#312f8d"
                d="M 14 3.9902344 C 8.4886661 3.9902344 4 8.4789008 4 13.990234 L 4 35.990234 C 4 41.501568 8.4886661 45.990234 14 45.990234 L 36 45.990234 C 41.511334 45.990234 46 41.501568 46 35.990234 L 46 13.990234 C 46 8.4789008 41.511334 3.9902344 36 3.9902344 L 14 3.9902344 z M 18.005859 12.033203 C 18.633859 12.060203 19.210594 12.414031 19.558594 12.957031 C 19.954594 13.575031 20.569141 14.534156 21.369141 15.785156 C 22.099141 16.926156 22.150047 18.399844 21.498047 19.589844 L 20.033203 21.673828 C 19.637203 22.237828 19.558219 22.959703 19.824219 23.595703 C 20.238219 24.585703 21.040797 26.107203 22.466797 27.533203 C 23.892797 28.959203 25.414297 29.761781 26.404297 30.175781 C 27.040297 30.441781 27.762172 30.362797 28.326172 29.966797 L 30.410156 28.501953 C 31.600156 27.849953 33.073844 27.901859 34.214844 28.630859 C 35.465844 29.430859 36.424969 30.045406 37.042969 30.441406 C 37.585969 30.789406 37.939797 31.366141 37.966797 31.994141 C 38.120797 35.558141 35.359641 37.001953 34.556641 37.001953 C 34.000641 37.001953 27.316344 37.761656 19.777344 30.222656 C 12.238344 22.683656 12.998047 15.999359 12.998047 15.443359 C 12.998047 14.640359 14.441859 11.879203 18.005859 12.033203 z"
              ></path>
            </svg>

            {/* <img
              src="https://s3.ap-south-1.amazonaws.com/aimaradoors.pennonn.com/icons/close.png"
              alt="logo"
              className="respIcon"
            /> */}
          </a>
          <button onClick={() => toggleDropdown()} className="btnDiv">
            {show ? (
              <img
                src="https://s3.ap-south-1.amazonaws.com/aimaradoors.pennonn.com/icons/close.png"
                alt="logo"
                className="respIcon"
              />
            ) : (
              <img
                src="https://s3.ap-south-1.amazonaws.com/aimaradoors.pennonn.com/icons/hamburger.png"
                alt="logo"
                className="respIcon"
              />
            )}
          </button>
        </div>
      </div>
      {show && (
        <div className="headerDropdown">
          <div className="navTxtResp">
            <Link
              onClick={() => setShow(false)}
              to="/home"
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt">HOME</p>
            </Link>
          </div>
          <div className="navTxtResp">
            <Link
              onClick={() => setShow(false)}
              to="/about-us"
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt" style={{ marginBottom: 0 }}>
                ABOUT
              </p>
            </Link>
          </div>
          <div className="navTxtResp">
            <Link
              onClick={() => setShow(false)}
              to="/roofing"
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt" style={{ marginBottom: 0 }}>
                ROOFING
              </p>
            </Link>
          </div>

          <Link
            onClick={() => setDrop(!drop)}
            // to="/service"
            style={{ textDecoration: "none" }}
          >
            <div className="dropDownParentDiv">
              <p className="navTxt">SERVICES</p>
              <div className="arrowIMg">
                <img
                  src={DA}
                  alt="arrow"
                  className="arrowImg"
                  style={{
                    transform: drop ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 1.0s",
                  }}
                />
              </div>
            </div>
          </Link>
          {drop && (
            <div
              className="dropDownDiv"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="storm-service"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">STORM SERVICE</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="roofing-service"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">ROOFING</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="roofing-company"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">ROOFING COMPANY</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="roof-repair"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">ROOF REPAIR</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="roof-inspection"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">ROOF INSPECTION</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="roof-restoration"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">ROOF RESTORATION</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="remodeling-company"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">REMODELING COMPANY</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="kitchen-remodeling"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">KITCHEN REMODELING</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
              <div>
                <Link
                  onClick={() => {
                    setDrop(false);
                    setShow(false);
                  }}
                  to="bedroom-remodeling"
                  style={{ textDecoration: "none" }}
                >
                  <p className="navTxt">BATHROOM REMODELING</p>
                </Link>
              </div>
              <div className="hrDrop"></div>
            </div>
          )}
          {/* <div className="navTxtResp">
            <Link
              onClick={() => setShow(false)}
              to="/blog"
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt" style={{ marginTop: 0 }}>
                BLOG
              </p>
            </Link>
          </div> */}
          <div className="navTxtResp">
            <Link
              onClick={() => setShow(false)}
              to="/contact-us"
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt">CONTACT</p>
            </Link>
          </div>

          <div className="rightEndHeader">
            <img src={BBB} alt="logo" className="logoBbb" />
          </div>
          <div className="rightEndHeader">
            <a href="tel:832-388-5393">
              <button className="callNowBtn">CALL NOW</button>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
